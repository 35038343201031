.mainContainer {
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.headingText {
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: var(--text-color);
  margin-bottom: 20px;
}

.otpSection input {
  width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0.5px solid #000000 !important;
}

.resend {
  display: flex;
  flex-direction: column;
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 122%;
  margin-top: 5px;
}

.resendSub {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.verifyBox {
  display: flex;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  height: 48px;
  justify-content: center;
  width: 87px;
  border: 1px solid var(--button-text-color-secondary);
  border-radius: 33px;
  margin-left: 10px;
}

.phoneVerifyWrapper input {
  width: 364px !important;
  height: 48px !important;
  border-radius: 10px !important;
  outline: none !important;
  box-shadow: none !important;
  border: 0.5px solid #000000 !important;
}

textarea {
  width: 364px;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  padding: 15px 20px;
  letter-spacing: 1px;
  font-family: var(--satoshi-font);
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.phoneVerifyWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.otpInputStyle {
  width: 48px !important;
  height: 48px !important;
  margin-top: 0px !important;
  color: var(--text-color);
  padding: 0px;
}

.otpSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.code {
  background: #ffae91;
  box-shadow: 0px 9px 30px rgba(255, 174, 145, 0.3);
  border-radius: 8px;
}

::placeholder {
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Cabin-light-italic;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-color);
  opacity: 0.4;
  font-style: italic;
}

.wait {
  font-family: "PublicSans";
  font-weight: bold;
  font-size: 12px;
}

.verifiedTag {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border: 1px solid var(--service-name-header);
  border-radius: 33px;
  height: 48px;
  padding: 15px;
  cursor: pointer;
}

.resendOtp {
  color: var(--button-text-color-secondary);
  font-family: "PublicSans";
  font-weight: bold;
  font-size: 12px;
}

.verifiedTag p {
  margin: 0px;
  text-align: left;
  font-size: 14px;
  font-family: "PublicSans";
  font-weight: 700;
  color: green;
}

.info {
  margin: 0px;
  text-align: left;
  font-size: 10px;
  font-style: italic;
  font-family: "HelveticaNeue";
  font-weight: 400;
}

@media screen and (max-width: 1024px) {
  .react-tel-input {
    width: auto !important;
  }
  .otpSection input {
    height: 40px !important;
    width: 40px !important;
  }
  .phoneVerifyWrapper input {
    width: 320px !important;
  }
  textarea {
    width: 320px !important;
  }

  .phoneVerifyWrapper {
    margin-bottom: 10px;
    width: fit-content;
  }
}
@media screen and (max-width: 767px) {
  .react-tel-input {
    width: 100%;
  }

  textarea {
    width: 66% !important;
  }
  .phoneVerifyWrapper input {
    width: 81% !important;
  }
  .phoneVerifyWrapper {
    margin-bottom: 10px;
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  .phoneVerifyWrapper input {
    width: 100% !important;
  }

  textarea {
    width: 100% !important;
  }
}

.mainContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

.rightContainer {
  width: 65%;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20vw;
  margin-top: 15vh;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.title {
  font-family: "PublicSans";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  width: 50%;
}

.subtitle {
  margin-top: 20px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: var(--text-color);
  line-height: 130.8%;
}

.email {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: var(--button-text-color-secondary);
  line-height: 130.8%;
}

.stepper {
  margin-top: 15px;
}

.stepperActive {
  display: inline-block;
  border-bottom: 2px solid #3147ed;
  width: 60px;
  margin: 5px;
}

.stepperInactive {
  display: inline-block;
  border-bottom: 2px solid #cacaca;
  width: 60px;
  margin: 5px;
}

.label {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--auth-form-heading-text-color);
  margin-top: 20px;
}

.input {
  box-sizing: border-box;
  border: 1px solid #8b8b8b;
  border-radius: 8px;
  height: 54px;
  width: 300px;
}

.phoneInputDiv input {
  box-sizing: border-box;
  border: 1px solid #8b8b8b !important;
  border-radius: 8px !important;
  height: 54px !important;
  width: 300px !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn {
  height: 54px;
  color: var(--button-text-color);
  border-radius: 8px;
  color: var(--service-cards-background);
  border: 0px;
  cursor: pointer;
  width: 300px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.disabledBtn {
  height: 54px;
  color: var(--button-text-color);
  opacity: 0.58;
  border-radius: 8px;
  color: var(--service-cards-background);
  border: 0px;
  cursor: pointer;
  width: 300px;
  margin-top: 25px;
}

.contactLabel {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--auth-cts-heading);
  margin-top: 20px;
}

.contactLink {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--button-text-color);
  text-align: center;
  cursor: pointer;
}

.otpLabel {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: var(--auth-form-heading-text-color);
}

.phoneNumber {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--button-text-color-secondary);
}

.otpInputStyle {
  width: 58px !important;
  height: 75px !important;
  margin-top: 20px !important;
  color: var(--text-color);
  padding: 20px !important;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
}

.otpContainerStyle {
  justify-content: space-between;
  margin-top: 20px;
}

.verified {
  margin-top: 20px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: var(--button-text-color);
}

.modalOverlay {
  position: fixed;
  inset: 0px;
  background-color: var(--modal-overlay);
}

.modalStyles {
  background: var(--service-cards-background);
  mix-blend-mode: normal;
  border-radius: 8px;
  position: absolute;
  width: fit-content;
  z-index: 20;
  height: fit-content;
  inset: 40px;
  border: none;
  margin: auto;
}

.modTitle {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 1px;
  color: var(--text-color);
  margin-top: 35px;
}

.modSubTitle {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  color: var(--auth-subtitle-text-color);
  width: 70%;
  margin-top: 25px;
  margin-bottom: 15px;
}

.modalCloseDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding-right: 2rem;
  padding-top: 2rem;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
}

.modalCloseImage {
  cursor: pointer;
}

.error {
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--error-text-color);
  width: 300px;
  text-align: left;
  margin-bottom: 0px;
}

.errorImg {
}

.errorDiv {
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  box-sizing: border-box;
  background: #fff3f2;
  border: 1px solid #ffbbbb;
  border-radius: 8px;
}

.logoImg {
  display: none;
  height: 43px;
  width: 200px;
  margin-bottom: 50px;
}

.footerLinks {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  font-family: "HelveticaNeue";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  justify-content: center;
  display: none;
}

.divider {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #000000;
}

.inputDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  border: 1px solid #8b8b8b;
  border-radius: 8px;
}

.inputDiv input {
  border: none;
  width: 100%;
}

.loader {
  width: 20%;
  height: 55px;
}

.buttonText {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.buttonTextLoading {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 20%;
}

.loaderImg {
  width: 55px;
  height: 50px;
}

.eyeIcon {
  cursor: pointer;
}

.btnLogin {
  color: var(--button-text-color);
  border-radius: 8px;
  color: var(--service-cards-background);
  border: 0px;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .rightContainer {
    width: 100%;
  }
  .outerContainer {
    align-items: center;
    margin-top: 0%;
    margin-left: 0%;
  }
  .mainContainer {
    align-items: center;
  }
  .footerLinks {
    display: flex;
  }
  .logoImg {
    display: block;
  }
  .title {
    width: 100%;
    text-align: center;
  }
  .modalContainer {
    width: 400px;
  }
}

@media screen and (max-width: 560px) {
  .modalContainer {
    width: 300px;
  }
}

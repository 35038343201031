.mainContainer {
  /* height: 55vh; */
  min-height: 38vh;
  width: 100vw;
  /* position: relative; */
  /* background: url("../../../assets/images/dashboard_mood.png");
  background-size: cover; */
  /* position: fixed;
  top: 0; */
  /* background-color: cornflowerblue; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mood {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.moodMobile {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.imgContainer {
  /* background-color: bisque; */
  position: relative;
  /* height: 80%; */
  /* background: url("../../../assets/images/dashboard_mood.png");
  background-size: cover; */
}

.headerDetailsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  background: url("../../../assets/images/dashboard_mood.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 32vh;
  width: 100vw;
  background-position: bottom;
}

.moodBack {
  width: 100vw;
  height: 43vh;
  background-size: cover;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: auto;
}

.nav {
  display: flex;
  flex-direction: row;
  padding: 40px;
  background-color: transparent;
  justify-content: space-between;
}

.navBtn {
  height: 44px;
  width: 114px;
  color: var(--button-text-color-secondary);
  background-color: transparent;
  border: 1px solid var(--button-text-color-secondary);
  border-radius: 33px;
}

.viewAll {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.mainHeadingWrapper {
  max-width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.notifyDiv {
  background-color: var(--service-cards-background);
  opacity: 0.5;
  height: 90%;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
}
.backBtnWrapper {
  width: 100%;
  color: var(--button-text-color-secondary);
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.upcomingAppointmentsWrapper {
  display: flex;
  height: 15vh;
  width: 30.66%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
  align-self: flex-start;
  position: absolute;
  top: 30px;
  right: 40px;
}

.headingTextWrapper {
  font-family: PublicSans;
  margin-left: 17px;
}

.headingText {
  font-size: 30px;
  line-height: 33px;
  text-align: left;
  font-family: "PublicSans";
  font-weight: 300;
}

.viewAll p {
  text-decoration: none;
  font-size: 14px;
  margin-right: 5px;
  margin-top: 10px;
  font-family: Cabin-medium;
  font-weight: bold;
  color: var(--button-text-color-secondary);
  line-height: 20px;
  cursor: pointer;
}

.notifyDiv p {
  color: var(--button-text-color);
  text-align: left;
  font-family: "HelveticaNeue";
  font-style: italic;
  font-size: 12px;
}
.btnArrow {
  margin-right: 5px;
  filter: invert(0%) sepia(100%) saturate(11%) hue-rotate(318deg)
    brightness(102%) contrast(98%);
}

.subHead2 {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 80%;
  height: 50px;
  background-color: var(--service-cards-background);
  border-radius: 15px;
}

.subHead2Para {
  font-size: 16px;
  line-height: 16px;
}

.appointmentWrapper {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  mix-blend-mode: normal;
  background-color: var(--upcoming-appointment-details-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.docExtraImage {
  width: 14px;
  height: 14px;
}

.doctorName {
  font-size: 12px;
  line-height: 17px;
  font-family: var(--satoshi-font);
  font-weight: 400;
  margin: 0px;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  width: 100%;
}

.doctorNameMobile {
  font-size: 12px;
  line-height: 17px;
  font-family: var(--satoshi-font);
  font-weight: 400;
  margin: 0px;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  width: 100%;
  display: none;
}

.appTime {
  font-size: 12px;
  line-height: 17px;
  font-family: Cabin-regular;
  font-weight: 400;
  margin: 0px;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  width: 100%;
}

.doctorProfileImage {
  height: 36px;
  width: 36px;
  border-radius: 8px;
}

.time p {
  font-family: Cabin-regular;
  margin-left: 5px;
}

.notifications {
  font-size: 18px;
  text-align: left;
  margin: 0px;
  margin-left: 0px;
  font-family: var(--satoshi-font);
  font-weight: 500;
}

.back {
  margin: 0px;
  font-family: Cabin-regular;
  font-weight: 700;
  font-size: 14px;
}

.expandDoctorImage {
  height: 55px;
  width: 65px;
  margin: 5px;
  border-radius: 8px;
}
.appointmentTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  text-align: left;
  margin-left: 15px;
}

.time {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.callIconWrapper {
  width: 42px;
  height: 42px;
  mix-blend-mode: multiply;
  background-color: var(--upcoming-appointment-camera-background);
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}
.expandCallIcon {
  width: 75px;
  height: 50px;
  mix-blend-mode: multiply;
  background-color: var(--upcoming-appointment-camera-background);
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}
.displayAll {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100vh;
  background-color: var(--service-cards-background);
  border-radius: 25px 0px 0px 25px;
  transition: 1ms;
  animation: to-left-1 1s linear forwards;
}

.expandAppointmentWrapper {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  mix-blend-mode: normal;
  background-color: var(--upcoming-appointment-camera-background);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}

.blur {
  filter: blur(2px);
}

.doctor {
  align-self: flex-end;
}

.appointmentDetails {
  display: flex;
  align-items: center;
  width: 80%;
}

@media screen and (min-width: 1440px) {
  .mainHeadingWrapper {
    max-width: 40%;
  }
}

@media screen and (max-width: 1200px) {
  .headerDetailsWrapper {
    padding-right: 5%;
  }
  .displayAll {
    width: 35%;
  }
}

@media screen and (max-width: 1024px) {
  .headerDetailsWrapper {
    padding-right: 1%;
  }
  .upcomingAppointmentsWrapper {
    width: 37%;
    top: 10px;
  }
  .displayAll {
    width: 40%;
  }
  .imgContainer {
    height: 40vh;
  }
  .appointmentDetails {
    width: 85%;
  }
}

@media screen and (max-width: 768px) {
  .mood {
    display: none;
  }
  .moodMobile {
    display: block;
  }
  /* .moodBack {
    background: url("../../../assets/images/dashboard_mobile_mood.svg");
    background-repeat: no-repeat;
  } */
  .upcomingAppointmentsWrapper {
    width: 42%;
    top: 0px;
    right: 20px;
  }
  .headerDetailsWrapper {
    height: 37vh;
    background: url("../../../assets/images/dashboard_mobile_mood.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
  .appointmentDetails {
    width: 75%;
    align-items: center;
  }
  .displayAll {
    width: 60%;
  }
  .imgContainer {
    height: auto;
  }
}

@media screen and (max-width: 560px) {
  .mainHeadingWrapper {
    max-width: 100%;
  }
  .upcomingAppointmentsWrapper {
    width: 50%;
    right: 10px;
    gap: 5px;
    padding-right: 0px;
    padding-left: 15px;
  }
  .appointmentWrapper {
    padding: 6px;
  }
  .headingTextWrapper {
    margin-left: 12px;
  }
  .headerDetailsWrapper {
    margin-bottom: 20px;
  }
  .appointmentDetails {
    width: 85%;
  }
  .displayAll {
    width: 70%;
    max-width: 90vw;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .doctorName {
    display: none;
  }
  .doctorNameMobile {
    display: block;
  }
  .mainContainer {
    justify-content: flex-start;
  }
  .appointmentWrapper {
    margin-top: 0px;
  }
  .callIconWrapper {
    width: 32px;
    height: 32px;
  }
  .doctorProfileImage {
    width: 32px;
    height: 32px;
  }
  .appointmentTextWrapper {
    margin-left: 10px;
  }
  .viewAll p {
    margin-top: 0px;
    font-size: 12px;
    line-height: normal;
  }
  .viewAll img {
    width: 9px;
    height: 9px;
  }
  .notifications {
    font-size: 16px;
  }
  .viewAll {
    margin-left: 5px;
  }
}

@media screen and (max-width: 425px) {
  .headingText {
    font-size: 26px;
  }
  .displayAll {
    width: 100%;
  }
  .upcomingAppointmentsWrapper {
    width: 55%;
    right: 10px;
    gap: 5px;
    padding-right: 0px;
    padding-left: 15px;
  }
  .mainContainer {
    justify-content: flex-start;
  }
  .callIconWrapper {
    width: 32px;
    height: 32px;
  }
  .appTime {
    font-size: 10px;
  }
  .appointmentTextWrapper {
    margin-left: 8px;
  }
  .imgContainer {
    height: 28vh;
  }
  .appointmentWrapper {
    margin-top: 0px;
  }
}

@media screen and (max-width: 380px) {
  .imgContainer {
    height: 28vh;
  }
}

@media screen and (max-height: 750px) {
  .headerDetailsWrapper {
    height: 52vh;
  }
}

@media screen and (min-height: 750px) {
  .headerDetailsWrapper {
    height: 40vh;
  }
}

@media screen and (min-height: 750px) and (min-width: 1500px) {
  .headerDetailsWrapper {
    height: 47vh;
  }
}

@media screen and (min-height: 800px) and (min-width: 1500px) {
  .headerDetailsWrapper {
    height: 52vh;
  }
}

.contentWrapper {
  margin-top: 0%;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 20px;
  padding-top: 0px;
  margin-right: 0px;
  border-radius: 30px;
  background-color: transparent;
  width: 35%;
  max-height: 70vh;
}

.stepsDiv {
  display: flex;
  flex-direction: row;
  background-color: transparent;
}

.sideboxWrapper {
  width: 100%;
  margin-right: 50px;
  /* background-color: var(--appointment-steps-background); */
  background-color: #fff2e5;
  /* padding-left: 20px; */
  /* padding-top: 30px; */
  padding-bottom: 0px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
}

.stepperNav {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.stepperStepNumber {
  height: 28px;
  width: 28px;
  background-color: #ffac57;
  border-radius: 30px;
}

.step {
  display: flex;
  flex-direction: column;
  width: 27%;
  font-family: "HelveticaNeue";
  font-weight: 400;
}
.docImg {
  /* margin-top: 20px;
  width: 60%; */
  /* height: 180px; */
}
.header {
  /* margin-bottom: 24px; */
  line-height: 24px;
  font-size: 20px;
  color: var(--text-color);
  font-family: PublicSans;
  font-weight: 400;
}

.stepsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: none;
}

.stepNumber {
  font-size: 14px;
  margin: 0px;
}
.stepDescription {
  font-size: 12px;
  margin: 0px;
  font-style: italic;
}

.GqQP1MOuOgqmGBuhjbaj {
  background-color: #ffac57;
}

@media screen and (max-width: 1200px) {
  .contentWrapper {
    width: 40%;
  }
  .sideboxWrapper {
    /* margin-right: 25px; */
  }
}

@media screen and (max-width: 1024px) {
  .contentWrapper {
    width: 40%;
  }
  .sideboxWrapper {
    /* margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px; */
  }
  .step {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  .docImg {
    display: none;
  }
  .contentWrapper {
    width: 100%;
    padding: 0px;
  }
  .sideboxWrapper {
    /* padding: 15px 20px 15px 20px;
    border-radius: 20px;
    align-items: flex-start; */
  }
  .stepDescription {
    margin: 0px;
    font-size: 12px;
  }
  .header {
    margin-bottom: 10px;
  }
  .step {
    width: 30%;
  }
  .stepsWrapper {
    width: 100%;
  }
  .stepperNav {
    margin-top: 0px;
  }
}

.leftContainer {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 40px;
  padding-top: 40px;
  background-color: #009aff;

  /* padding-bottom: 5vh; */
}

.logoImg {
  width: 80%;
}

.illustration {
  /* height: 65vh; */
  /* width: 80%; */
}

.leftHeader {
  font-family: var(--font-heading-medium);
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  /* margin-inline: 40px; */
  white-space: pre-wrap;
  color: #004b75;
}

.footerLinks {
  display: flex;
  flex-direction: row;
  font-family: "PublicSans";
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  align-items: flex-end;
  margin-left: 70px;
}

.footerLinks a {
  text-decoration: none;
}

.divider {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #000000;
}

@media screen and (min-width: 1300px) {
  .illustration{
    width: 90%;
  }
}

@media screen and (max-width: 1280px) {
  .leftHeader,
  .footerLinks {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .leftContainer {
    width: 40%;
  }
  .logoImg {
    /* margin-left: 25px; */
  }
  .leftHeader {
    margin-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .leftContainer {
    display: none;
  }
}

@media screen and (max-height: 768px) {
  .illustration{
    height: 55vh;
  }
}

@import "./styles/custom-font.css";
@import "./variables.css";
@import "./styles/moodahead/moodahead.css";
@import "./styles/moodahead/moodahead_variables.css";
@import "./styles/moodahead/moodahead_booking_flow.css";

:root {
  --card-color: f0f0f0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Cabin-regular;
  color: black;
}

@media only screen and (max-width: 425px) {
  .container {
    padding: 0 20px;
  }
}

html {
  overflow: hidden;
  width: 100%;
}

body {
  height: 100%;
  position: fixed;
  /* prevent overscroll bounce*/

  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
/* iOS velocity scrolling */

.scrollableContainer {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  overflow-x: hidden;
  overflow-y: scroll;
}

input {
  width: 80%;
  height: 56px;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 11px;
  outline: none;
  padding-left: 15px;
  font-family: var(--satoshi-font);
  font-weight: 400;
}

.sideboxImgCompanyStyles {
  margin-top: 0px !important;
  /* align-self: stretch; */
}

.sideboxWrapperCompanyStyles {
  /* padding-left: 0px !important;
  padding-right: 0px !important; */
  padding-inline: 24px;
  padding-top: 32px;
}

.sideboxHeaderCompanyStyles {
  /* padding-inline: 20px; */
  font-family: var(--satoshi-font) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #202328 !important;
  align-self: flex-start;
}

.stepsWrapperCompanyStyles {
  padding-inline: 20px;
}

.slotHeaderCompnayStyles {
  font-size: 12px !important;
  font-family: Cabin-bold;
}

.nxtBtnCompanyStyles {
  border: none !important;
  text-transform: uppercase;
}

.backButtonWrapperCompnayStyles {
  /* display: none !important; */
  border: 2px solid #ffbe00;
  font-family: var(--satoshi-font) !important;
  font-size: 16px !important;
  padding: 10px 32px !important;
  border-radius: 62px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #202328 !important;
}

.almostTextCompanyStyles {
  display: none !important;
}

.buttonDivCompanyStyles {
  justify-content: flex-end !important;
}

.bookingStepHeadingTextCompanyStyles {
  color: black !important;
  /* font-family: Gelica-medium !important; */
  font-family: var(--satoshi-font) !important;
  font-size: 18px !important;
  line-height: 23px;
  margin-left: 0px !important;
}

.stepperTickCompanyStyles {
  display: none !important;
}

.availableDateCompanyStyle {
  /* border: 1px solid black !important; */
  font-size: 16px !important;
  font-family: Cabin-medium !important;
}

.monthLabelCompanyStyle {
  margin-bottom: 10px !important;
  display: none !important;
}

.dateWrapperCompanyStyles {
  padding-block: 8px !important;
  height: auto !important;
  /* font-family: Cabin-semi-bold !important;
  font-size: 16px !important; */
  border: 3px solid transparent;
  box-sizing: border-box;
}

.disabledDateCompanyStyle {
  font-size: 14px !important;
  font-family: Cabin-medium !important;
  opacity: 37% !important;
}

.selectedDateCompanyStyle {
  font-style: normal !important;
  font-family: Cabin-bold !important;
}

.doctorInfoWrapperCompanyStyles {
  width: 215px !important;
}

.bookingDoctorImg {
  height: 36px !important;
  width: 36px !important;
}

.bookingDoctorDescriptionCompanyStyles {
  display: block !important;
  font-family: var(--satoshi-font) !important;
  line-height: 16px;
  font-size: 12px !important;
}

.bookingDoctorName {
  font-family: Cabin-medium !important;
}

.bookingTimeWrapperCompanyStyles {
  /* width: 68px; */
  /* font-family: Cabin-medium !important; */
}

.timeSelectedCompanyStyle {
  font-family: HelveticaNeue !important;
  font-style: normal !important;
}

.stepNumberCompanyStyles {
  font-family: Cabin-medium !important;
}

.stepDescriptionCompanyStyles {
  font-family: Cabin-regular !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 19.6px;
}

.docExpCompanyStyles {
  display: none;
}

.bookingDateContentCompanyStyles {
  display: block;
}

.bookingDateContentAurora {
  display: none;
}

@media screen and (max-width: 1024px) {
  .serviceCardCompanyStyles {
    width: 45% !important;
  }
}

@media screen and (max-width: 768px) {
  .serviceCardCompanyStyles {
    width: 80% !important;
  }
}

@media screen and (max-width: 560px) {
  .serviceCardCompanyStyles {
    width: 90% !important;
  }
}

@media screen and (max-width: 425px) {
  .doctorInfoWrapperCompanyStyles {
    width: 80% !important;
  }
}

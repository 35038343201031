.doctorsWrapper {
  display: flex;
  width: 100%;
  padding: 0px;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  flex-direction: row;
  overflow-x: scroll;
}

.scrollableWrapper {
  flex-direction: row;
  display: flex;

  scrollbar-width: none;
  gap: 10px;
  overflow: hidden;
  width: 90%;
  margin-inline: 20px;
}

.doctorsWrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.doctorImg {
  /* margin-right: 8px; */
  height: 35px;
  width: 35px;
  border-radius: 8px;
}

.docName {
  font-size: 14px;
  line-height: 19px;
  margin: 0px;
  text-align: start;
  font-family: var(--satoshi-font);
  font-weight: 500;
}
.doctorInfoWrapper {
  width: 530px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0.5px solid #000000;
  border-radius: 8px;
  /* padding: 5px; */
  padding: 8px 30px 8px 12px;
  font-family: "HelveticaNeue";
  font-family: 400;
  cursor: pointer;
  gap: 8px;
}

.docNameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 85%;
}

.docExp {
  font-size: 8px;
  font-weight: 300;
}

.docDescription {
  display: none;
  font-size: 12px;
  font-weight: 400;
  color: #4b5262;
  font-family: var(--satoshi-font);
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.selectedStyle {
  /* background-color: var(--selection-color); */
  background-color: #fff7e1;
  /* border-color: transparent; */
  border: 3px solid #ffbe00;
  /* border-color: #ffbe00; */
  font-weight: bold;
  /* text-align: center; */
}

.deselectedStyle {
  background-color: var(--unselected-doctor-background);
  /* border: 0.5px solid #000000; */
  border: 1px solid #4b526233;
}

.buttonWrapper {
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
}
.buttonWrapper img {
  height: 12px;
  width: 12px;
}
.hide {
}

@media screen and (max-width: 560px) {
  .scrollableWrapper {
    flex-wrap: wrap;
    gap: 15px;
    margin-inline: 0px;
  }
  .doctorInfoWrapper {
    padding: 10px;
    width: 155px;
  }
  .buttonWrapper {
    display: none;
  }
  .hide {
    display: none !important;
  }
  .docNameWrapper {
    width: 80%;
  }
}

@media screen and (max-width: 425px) {
  .doctorInfoWrapper {
    width: 85%;
  }
}

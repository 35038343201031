@font-face {
  font-family: "Cabin-regular";
  src: url("../../assets/fonts/moodahead/Cabin/static/Cabin-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Cabin-medium";
  src: url("../../assets/fonts/moodahead/Cabin/static/Cabin-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Cabin-semi-bold";
  src: url("../../assets/fonts/moodahead/Cabin/static/Cabin-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Cabin-bold";
  src: url("../../assets/fonts/moodahead/Cabin/static/Cabin-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Cabin-light-italic";
  src: url("../../assets/fonts/moodahead/Cabin/static/Cabin-Italic.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Gelica-light";
  src: url("../../assets/fonts/moodahead/Gelica/Fontspring-DEMO-gelica-light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Gelica-medium";
  src: url("../../assets/fonts/moodahead/Gelica/Fontspring-DEMO-gelica-medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Gelica-semi-bold";
  src: url("../../assets/fonts/moodahead/Gelica/Fontspring-DEMO-gelica-semibold.otf");
  font-weight: 600;
}

.serviceBookBtnCompanyStyles {
  padding: 12px 18px !important;
  font-family: var(--satoshi-font) !important;
  font-size: 14px !important;
  text-transform: uppercase;
  gap: 5px;
  line-height: 22px;
  max-width: 150px;
  letter-spacing: 0.56px;
  align-self: flex-end;
  font-weight: 600 !important;
}

.serviceCardCompanyStyles {
  padding: 20px !important;
  width: 36% !important;
  /* margin-bottom: 4% !important; */
}

.serviceTitleCompanyStyles {
  /* font-family: Gelica-medium !important; */
  font-family: var(--satoshi-font) !important;
  font-weight: 700 !important;
  color: #202328 !important;
  font-size: 24px !important;
  margin-bottom: 8px !important;
  line-height: 29px !important;
}

.serviceDescriptionCompanyStyles {
  font-family: var(--satoshi-font) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19.6px !important;
}

.cardsWrapperCompanyStyles {
  margin-top: 30px !important;
  margin-bottom: 0px !important;
  margin-inline: 4vw !important;
  /* justify-content: space-between !important; */
  gap: 4% !important;
}

.illustrationCompanyStyles {
  width: 70px !important;
  height: 68px !important;
  margin-top: 6px;
}

.serviceCardBtnRowCompanyStyles {
  align-items: start !important;
  margin-bottom: 12px !important;
}

.footerDivCompanyStyles {
  display: none !important;
}

.mainHeadingWrapperCompanyStyles {
  display: none !important;
}

.moodaheadHappyMood {
  display: block !important;
  position: absolute;
  top: 2vh;
  left: 12vw;
}

.headerWrapperCompanyStyles {
  padding-inline: 4vw !important;
  justify-content: end !important;
}

.planWrapperCompanyStyles {
  display: none;
}

.headerContentWrapperCompnayStyles {
  height: 49vh;
}

.moodaheadHappyMood img {
  height: 54vh;
}

.serviceCardBtnArrowCompanyStyles {
  filter: invert(5%) sepia(100%) saturate(9%) hue-rotate(314deg) brightness(96%)
    contrast(87%);
  height: 10px !important;
}

.additionalDoctorsWrapperCompanyStyles {
  display: none !important;
}

.logoutIconCompanyStyles {
  filter: invert(13%) sepia(93%) saturate(2916%) hue-rotate(186deg)
    brightness(94%) contrast(101%);
}

.stepper {
  padding: 0px !important;
}

.verticalLabelRight .label {
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}

.verticalStepper .stepConnector {
  min-height: 10px !important;
}

.YQZiU8D8fXz6FUlYYoMx {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.verticalStepperWrapper {
  gap: 10px;
}

@media screen and (max-width: 1280px) {
  .headerWrapperCompanyStyles {
    padding-inline: 1.8% !important;
  }
  .cardsWrapperCompanyStyles {
    margin-inline: 1.8% !important;
  }
}

@media screen and (max-width: 1024px) {
  .moodaheadHappyMood {
    left: 1vw;
    top: 7vh;
  }

  .moodaheadHappyMood img {
    height: 50vh;
  }
}

@media screen and (max-width: 768px) {
  .serviceCardCompanyStyles {
    width: 47% !important;
  }
  .moodaheadHappyMood {
    top: 12vh;
    left: -4vw;
  }

  .moodaheadHappyMood img {
    height: 35vh;
  }
}

@media screen and (max-width: 560px) {
  .serviceCardCompanyStyles {
    width: 100% !important;
    margin-bottom: 6vw !important;
  }
  .cardsWrapperCompanyStyles {
    margin-inline: 6vw !important;
  }
  .moodaheadHappyMood img {
    height: 35vh;
  }
  .moodaheadHappyMood {
    left: 10vw;
  }
  .headerContentWrapperCompnayStyles {
    justify-content: space-between;
    height: 75vh;
  }
}

@media screen and (max-width: 425px) {
  .moodaheadHappyMood {
    left: -5vw;
  }
}

@media screen and (max-width: 375px) {
  .moodaheadHappyMood {
    left: -10vw;
  }
}

@media screen and (max-height: 750px) {
  .moodaheadHappyMood {
    top: 15vh;
    left: 8vw;
  }
}

@media screen and (max-height: 600px) {
  .moodaheadHappyMood {
    top: 15vh;
    left: 15vw;
  }
}

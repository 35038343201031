.scrollNextContainer {
  background-color: #ffbe00;
  border: 1px solid #4b5262;
  border-radius: 30px;
  height: 32px;
  width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chevronArrow {
}

.mainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rightContainer {
  width: 65%;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20vw;
  height: 100%;
  justify-content: center;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.title {
  /* font-family: var(--font-heading-medium); */
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 42px;
}

.stepper {
  margin-top: 15px;
}

.stepperActive {
  display: inline-block;
  border-bottom: 2px solid #3147ed;
  width: 60px;
  margin: 5px;
}

.stepperInactive {
  display: inline-block;
  border-bottom: 2px solid #cacaca;
  width: 60px;
  margin: 5px;
}

.label {
  font-family: var(--font-text-medium);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--auth-form-heading-text-color);
  margin-top: 15px;
  margin-bottom: 2px;
}

.input {
  box-sizing: border-box;
  border: 1px solid #8b8b8b;
  border-radius: 11px;
  height: 54px;
  width: 300px;
  box-sizing: border-box;
  border-radius: 11px;
  padding-left: 15px;
  font-weight: 400;
}

.phoneInputDiv input {
  box-sizing: border-box;
  border: 1px solid #8b8b8b !important;
  border-radius: 8px !important;
  height: 54px !important;
  width: 300px !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn {
  height: 54px;
  background: var(--card-button-background);
  border-radius: 8px;
  color: var(--button-text-color);
  border: 0px;
  cursor: pointer;
  width: 300px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: var(--satoshi-font);
  font-weight: 700;
}

.disabledBtn {
  height: 54px;
  background: var(--card-button-background);
  opacity: 0.58;
  border-radius: 8px;
  color: var(--button-text-color);
  border: 0px;
  cursor: pointer;
  width: 300px;
  margin-top: 25px;
  pointer-events: none;
  font-family: var(--satoshi-font);
  font-weight: 700;
}

.contactLabel {
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--auth-cts-heading);
  margin-top: 20px;
}

.contactLink {
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--button-text-color);
  text-align: center;
  cursor: pointer;
}

.otpLabel {
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: var(--auth-form-heading-text-color);
}

.expireLabel {
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: var(--auth-form-heading-text-color);
  margin-top: 10px;
}

.phoneNumber {
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: var(--button-text-color-secondary);
}

.otpInputStyle {
  width: 60px !important;
  height: 75px !important;
  /* margin-top: 20px !important; */
  color: var(--text-color);
  padding: 20px !important;
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  border: 1px solid black;
}

.otpContainerStyle {
  justify-content: space-between;
}

.error {
  font-family: var(--satoshi-font);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--error-text-color);
  width: 300px;
  text-align: left;
  margin-bottom: 0px;
}

.errorDiv {
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  box-sizing: border-box;
  background: #fff3f2;
  border: 1px solid #ffbbbb;
  border-radius: 8px;
}

.inputDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  border: 1px solid #8b8b8b;
  border-radius: 8px;
}

.inputDiv input {
  border: none;
  width: 100%;
}

.logoImg {
  display: none;
  width: 225px;
  margin-bottom: 50px;
}

.footerLinks {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  font-family: var(--satoshi-font);
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  justify-content: center;
  display: none;
}

.divider {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #000000;
}

.loader {
  width: 20%;
  height: 55px;
}

.buttonText {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.buttonTextLoading {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 20%;
}

.loaderImg {
  width: 55px;
  height: 50px;
}

.eyeIcon {
  cursor: pointer;
}

.innerContainer input {
  font-family: var(--satoshi-font);
}

.radioInput {
  height: 16px;
  width: 16px;
  margin-right: 6px;
}

.radioInputDiv {
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioInputDiv span {
  font-size: 16px;
  line-height: 18px;
  font-family: var(--satoshi-font);
  font-weight: 500;
}

.genderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.rightContainer::-webkit-scrollbar {
  display: none;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.outerContainer::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .mainContainer {
    overflow: hidden;
  }
  .rightContainer {
    overflow-y: scroll;
  }
  .outerContainer {
    height: auto;
    padding-block: 40px;
    min-height: 100vh;
  }
}

@media screen and (max-width: 1024px) {
  .outerContainer {
    margin-left: 20%;
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
    justify-content: center;
    align-items: center;
    padding-bottom: 20vh !important;
  }
  .rightContainer {
    width: 100%;
  }
  .outerContainer {
    align-items: center;
    margin: 0px;
    justify-content: center;
  }
  .footerLinks {
    display: flex;
  }
  .logoImg {
    display: block;
  }
  .mainContainer {
    padding-block: 40px;
    /* height: auto; */
  }
}

@media screen and (max-width: 560px) {
  .outerContainer {
    padding-inline: 20px;
    align-items: flex-start;
  }
  .phoneInputDiv {
    width: 100%;
  }
  .phoneInputDiv input {
    width: 100% !important;
  }
  .innerContainer {
    width: 100%;
  }
  .disabledBtn {
    width: 100%;
  }
  .btn {
    width: 100%;
  }
  .stepper {
    margin-top: 25px;
  }
  .otpContainerStyle {
    justify-content: flex-start;
    gap: 10px;
  }
}

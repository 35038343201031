:root {
  --card-color: #f0f0f0 !important;
  --card-color-opacity-50: rgba(240, 240, 240, 0.5) !important;
  --service-name-header: #147c65 !important;
  --button-text-color: #1e1e1e !important;
  --button-text-color-secondary: #1e1e1e !important;
  --card-button-background: #ffce33 !important;
  --selection-color: #ffce33 !important;
  --service-cards-background: #ffffff !important;
  --appointment-details-selection-heading: #147c65 !important;
  --appointment-steps-background: #fff7e3 !important;
  --upcoming-appointment-details-background: #ffffff50 !important;
  --upcoming-appointment-camera-background: #fff1e7 !important;
  --availability-modal-text-color: #5c5c5c !important;
  --modal-overlay: #00000050 !important;
  --text-color: #000000 !important;
  --auth-form-heading-text-color: #464646 !important;
  --auth-cts-heading: #9ca3ab !important;
  --error-text-color: #d50000 !important;
  --further-instructions-text-color: #00000080 !important;
  --booking-confirmation-service-name-text-color: #147c65 !important;
  --dropin-modal-border-color: #4ba998 !important;
  --droping-header-background-color: #f4f4f4 !important;
  --dropin-body-header-text-color: #113a39 !important;
  --dropin-app-screen-text-color: #b3c0bd !important;
  --dropin-download-link-text-color: #546362 !important;
  --footer-container-background-color: #e0f3f3 !important;
  --login-button0warning-text-color: #ff705c !important;
  --auth-left-panel-text-color: #64688b !important;
  --auth-subtitle-text-color: #515c67 !important;
  --dashboard-header-gradient: linear-gradient(
    -92.52deg,
    #0b9cff 32.04%,
    rgba(11, 156, 255, 0.08) 100%
  ) !important;

  --slot-header-text-color: #0198ff;
  --booking-flow-button-background: #ffce33 !important;
  /* --unselected-doctor-background: #f2ece5; */
  --unselected-doctor-background: #ffffff;
  --selected-doctor-background: #ffffff;
  --summary-box-color: rgba(11, 156, 255, 0.2);
  --font-heading-medium: Gelica-medium;
  --font-text-regular: Cabin-regular;
  --font-text-medium: Cabin-medium;
  --font-text-semibold: Cabin-semi-bold;
  --font-text-bold: Cabin-bold;

  --satoshi-font: Satoshi;
}

.step::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}
.bookingStepWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 15px;
}

.slotHeader {
  color: var(--slot-header-text-color);
  text-decoration: underline;
  font-weight: 400;
  font-family: PublicSans;
  cursor: pointer;
}

.slotHeader,
.price {
  margin-right: 10%;
}

.expandPanel {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.expandedPanel {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  height: 150px;
  width: 100%;
}

.bookingStepHeadingText {
  margin-left: 10px;
  font-family: PublicSans;
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color);
}

.bookingStepHeadingWrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 15px;
}

.selectDateHeadingWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.hrLine {
  width: 100%;
  border: 0px;
  border-top: 2px solid #bfe0e0;
  margin-top: 24px;
  margin-bottom: 24px;
  opacity: 0.5;
  box-sizing: border-box;
}
.modalOverlay {
  position: fixed;
  inset: 0px;
  background-color: var(--modal-overlay);
}

.modalStyle {
  background-color: transparent;
  position: absolute;
  inset: 40px;
  z-index: 20;
  width: fit-content;
  height: fit-content;
  min-height: 45vh;
  border-radius: 25px;
  overflow: visible;
  padding: 0px;
  border: none;
  margin: auto;
}

.modalContainer {
  background-color: white;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalpart1 {
  display: flex;
  flex-direction: column;
}

.modalHeadingWrapper {
  width: 100%;
}
.modalPart1Subpart2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 15px 30px 0px 0px;
}

input::placeholder {
  color: var(--text-color);
  opacity: 0.3;
}

.formDiv {
  display: flex;
  flex-direction: row;
}

.modalContent {
  padding: 2rem 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-top: 15px;
}

.inputDiv p {
  font-family: Cabin-medium;
}

.inputDiv input {
  width: 230px;
  height: 55px;
  padding: 12px;
  border: 1px solid #bbbfc4;
  font-family: Cabin-regular;
}

.heading {
  font-family: Gelica-mediun;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: var(--button-text-color);
  text-align: center;
}

.subHeading {
  font-family: Cabin-regular;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 142%;
  text-align: center;
  color: var(--availability-modal-text-color);
}

.formButton {
  color: var(--button-text-color);
  font-size: 14px;
  text-align: center;
  width: 103px;
  height: 44px;
  border-radius: 33px;
  border: none;
  margin: 20px;
}

.price {
  font-size: 16px;
  font-family: var(--satoshi-font);
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .modalContent {
    padding: 2rem 4rem;
  }
  .bookingStepWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 560px) {
  .modalContent {
    padding: 1rem 1rem;
  }
  .heading {
    font-size: 20px;
  }
  .subHeading {
    font-size: 12px;
  }
  .formDiv {
    flex-direction: column;
    align-items: flex-start;
  }
  .form {
    align-items: flex-start;
  }

  .slotHeader,
  .price {
    margin-right: 5%;
  }
}

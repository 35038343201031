.navbarWrapper {
  display: flex;
  flex-direction: row;
  padding: 40px;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  background-color: #009eff;
  padding-bottom: 0px;
  width: 100%;
  box-shadow: 0px 2px 8px 0px #2023281a;
  padding-inline: 5rem;
  padding-block: 16px;
}

.navbarWrapperNoBackground {
  display: flex;
  flex-direction: row;
  padding: 40px;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px;
  width: 100%;
  background-color: #fefbf6;
  box-shadow: 0px 2px 8px 0px #2023281a;
  padding-inline: 80px;
  padding-block: 10px;
  position: relative;
  z-index: 10;
}

.planWrapper {
  background: var(--selection-color);
  border-radius: 33px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--button-text-color);
  padding: 6px 15px;
  margin-left: auto;
  text-transform: uppercase;
  mix-blend-mode: var(--selection-color);
  margin-right: 16px;
  align-self: center;
}

.navImage {
  max-height: 50px;
  cursor: pointer;
}

.avatar {
  height: 40px;
  width: 40px;
}

.userWrapper {
  display: flex;
  gap: 8px;
  position: relative;
  cursor: pointer;
}

.dropdownWrapper {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f5f3ef;
  border-radius: 8px;
  padding: 16px;
  z-index: 5;
  width: 14vw;
  margin-top: 5px;
}

.dropdownOption {
  display: flex;
  flex-direction: column;
  font-family: var(--satoshi-font);
  font-weight: 500;
  font-size: 16px;
}

.dropdownHr {
  border-bottom: 1px solid #f5f3ef;
}

.userName {
  font-family: var(--satoshi-font);
  font-weight: 700;
}

.manageProfile {
  font-size: 14px;
  text-decoration: underline;
  color: #595751;
  cursor: pointer;
  font-family: var(--satoshi-font);
}

@media screen and (max-width: 1024px) {
  .navbarWrapper,
  .navbarWrapperNoBackground {
    padding-inline: 20px;
  }
  .dropdownWrapper {
    width: 20vw;
  }
  .navbarWrapper {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .dropdownWrapper {
    width: 25vw;
  }
  .navbarWrapper {
    padding: 20px;
  }
  .navImage {
    max-height: 50px;
  }
}

@media screen and (max-width: 560px) {
  .navbarWrapper,
  .navbarWrapperNoBackground {
    padding-inline: 5%;
    padding-bottom: 20px;
  }
  .navImage {
    max-height: 40px;
  }
  .avatar {
    width: 40px;
    height: 40px;
  }
  .planWrapper {
    font-size: 14px;
    margin-right: 8px;
    padding: 4px 10px;
  }
  .dropdownWrapper {
    width: 50vw;
  }
}

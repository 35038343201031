.calendarDiv1 {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  flex-direction: row;
}

.containDiv {
  /* max-width: 486px; */
  width: 100%;
  background-color: transparent;
}

.secondDiv1 {
  margin-left: 20px;
  padding-bottom: 5px;
  margin-right: 20px;
  flex-direction: row;
  display: flex;
  overflow: scroll;
  scrollbar-width: none;
  /* width: 90%; */
  /* width: 560px; */
  width: 35rem;
  gap: 20px;
}

.subDiv1 {
  font-family: "HelveticaNeue";
  font-weight: 400;
  text-align: center;
  padding: 5px;
  /* width: 60px; */
  width: 3.75rem;
  height: 70px;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  /* margin-right: 10px; */
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.secondDiv1::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.bookingDateContent {
  width: 45px;
  background-color: transparent;
  font-size: 16px;
  line-height: 19px;
  font-family: HelveticaNeue;
  /* margin-bottom: 2px; */
}

.bookingDateDay {
  width: 45px;
  background-color: transparent;
  font-size: 12px;
  line-height: 15px;
}

.bookingDateContent,
.bookingDateDay {
  color: #202328;
}

.subDiv1:hover {
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
}

.calendarImage {
  width: 20px;
  margin-right: 5px;
}
.monthLabel {
  position: sticky;
  width: 95px;
  top: 0;
  left: 0;
  height: 100%;
  padding: 5px;
  border-radius: 10px;
  align-content: center;
  color: var(--text-color);
  font-size: 12px;
  font-family: PublicSans;
  font-weight: 700;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: 0px;
}

.monthDiv {
  display: flex;
  flex-direction: column;
}
.heading {
  font-family: Helvetica;
  font-size: 25px;
  color: var(--button-text-color-secondary);
  font-weight: 300;
}
.monthLabelDiv {
  background-color: transparent;
  margin-bottom: 0px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-left: 0px;
}

.arrow {
  height: 14px;
  margin-top: 50px;
  width: 20px;
}

.datesDiv {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 20px;
}

.activeDate {
  background-color: transparent;
}
.activeDate1 {
  background-color: transparent;
}

.buttonWrapper1 {
  position: absolute;
  display: flex;
  align-items: flex-end;
  background: inherit;
  cursor: pointer;
}

.buttonWrapper2 {
  display: flex;
  align-items: flex-end;
  background: inherit;
  cursor: pointer;
}

.button {
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.selectedStyle {
  /* background-color: var(--selection-color); */
  background-color: #fff7e1;
  cursor: pointer;
  border: none !important;
  border-radius: 10px;
  /* font-family: HelveticaNeue !important; */
  font-style: italic;
  /* font-weight: bold; */
  text-align: center;
  border: 3px solid #ffbe00 !important;
}

.disabledStyle {
  pointer-events: none;
  box-shadow: none;
  opacity: 0.4 !important;
}

.availableStyle {
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
}

.dayName {
  margin-top: 8px;
  color: #4b5262;
}
@media screen and (max-width: 1200px) {
  .datesDiv {
    gap: 10px;
  }
  .subDiv1 {
    width: 3.5rem;
  }
  .secondDiv1 {
    width: 28.875rem;
    gap: 10px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .secondDiv1 {
    width: 27.125rem;
  }
  .subDiv1 {
    width: 3.25rem;
  }
}
@media screen and (max-width: 1024px) {
  .calendarDiv1 {
    width: 100%;
  }
  .containDiv {
    width: 100%;
  }
  .subDiv1 {
    width: 3.25rem;
  }
  .secondDiv1 {
    width: 23.25rem;
  }
}

@media screen and (max-width: 769px) {
  .calendarDiv1 {
    width: 100%;
  }
  .secondDiv1 {
    margin-left: 10px;
    margin-right: 10px;
    width: 22.5rem;
    gap: 20px;
  }
  .datesDiv {
    gap: 20px;
  }

  .containDiv {
    /* max-width: 396px; */
  }
}
@media screen and (max-width: 475px) {
  .datesDiv {
    gap: 10px;
  }
  .secondDiv1 {
    width: 19.375rem;
    gap: 10px;
  }
}
@media screen and (max-width: 475px) {
  .secondDiv1 {
    /* margin-right: 10px;
    margin-left: 30px; */
    width: 15.5rem;
  }
}
@media screen and (max-width: 426px) {
  .secondDiv1 {
    /* margin-right: 10px;
    margin-left: 30px; */
    /* width: 15.5rem; */
  }
  .subDiv1 {
    /* width: 50px;
    height: 80px; */
  }
  .containDiv {
    /* max-width: 286px; */
  }
  .buttonWrapper1 {
    margin-left: -10px;
    height: 100%;
    align-items: center;
  }
  .buttonWrapper2 {
    height: 100%;
    align-items: center;
  }
}

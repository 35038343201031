.serviceDescriptionWrapper {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-content: space-between;
  justify-content: space-between;
}
.serviceDetails {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  text-align: left;
  margin-left: 0px;
  align-items: flex-end;
  gap: 16px;
}

.serviceImg {
  height: 80px;
  width: 92px;
  display: none;
}

.doctorsWrapper {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  align-items: flex-end;
}

.responsiveDoctorsWrapper {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  display: none;
}

.headingHr {
  width: 98%;
  border: 0.5px solid #ff875c;
  margin-top: 25px;
  margin-bottom: 25px;
  display: none;
}

.serviceTextWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  background-color: transparent;
  font-family: "HelveticaNeue";
  font-weight: 400;
  gap: 12px;
  padding-bottom: 20px;
  /* max-width: 55%; */
}

.additionalDocImg {
  height: 45px;
  width: 45px;
  border-radius: 10px;
  border: 2px solid #eaf5f5;
  position: relative;
  left: -15px;
}

.serviceName {
  font-size: 32px;
  line-height: 44px;
  /* margin-bottom: 10px; */
  font-family: var(--satoshi-font);
  font-weight: 700;
}
.serviceDescription {
  font-size: 16px;
  line-height: 21px;
  margin: 0px;
  font-family: var(--satoshi-font);
}

.freeAppointmentContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding-inline: 20px;
  padding-block: 4px;
  background-color: #ff7f00;
  border-radius: 12px;
  height: max-content;
}

.freeAppointmentText {
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  text-align: left;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .serviceTextWrapper {
    /* max-width: 65%; */
  }

  .serviceDetails {
    align-items: flex-end;
  }
}

@media screen and (max-width: 560px) {
  .serviceImg {
    width: 70px;
  }
  .responsiveDoctorsWrapper {
    display: none;
    margin-left: 0px;
  }
  .doctorsWrapper {
    display: none;
  }
  .serviceDescription {
    /* display: none; */
  }
  .serviceTextWrapper {
    align-items: flex-start;
  }
  .additionalDocImg {
    height: 40px;
    width: 40px;
  }
  .serviceTextWrapper {
    /* margin-left: 20px; */
  }
  .serviceDetails {
    gap: 5px;
  }
}

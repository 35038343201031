.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  height: 100vh;
}

.loadingMessage {
  color: var(--button-text-color-secondary);
  font-family: "PublicSans";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--button-text-color-secondary);
  text-align: center;
}

.loadingAnimationBox {
  width: 200px;
  margin-bottom: 20px;
}

.container::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.timeSlotsWrapper {
  height: inherit;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  gap: 20px;
  /* margin-top: 10px; */
  /* width: 90%; */
  /* width: 560px; */
  width: 35rem;
  overflow-x: scroll;
  margin-inline: 18px;
}

.timeSlotsWrapper::-webkit-scrollbar {
  display: none;
}

.timeWrapper {
  padding: 6px 12px;
  border-radius: 10px;
  font-family: HelveticaNeue;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4b526233;
  cursor: pointer;
  color: #202328;
  /* width: 60px; */
  width: 3.75rem;
}

.noSlot {
  margin-left: 10px;
  font-size: 12px;
  font-family: "HelveticaNeue";
  font-weight: 400;
  font-style: italic;
}

.timeDead {
  font-family: HelveticaNeue;
  font-size: 16px;
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 7px;
}

.selectedStyle {
  /* background-color: var(--selection-color); */
  background-color: #fff7e1;
  border-color: transparent;
  border: 3px solid #ffbe00;
  border-radius: 10px;
  font-style: italic;
  text-align: center;
}

.timeSlotsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .timeSlotsWrapper {
    width: 28.875rem;
    gap: 10px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .timeSlotsWrapper {
    width: 27.125rem;
  }
  .timeWrapper {
    width: 3.25rem;
  }
}

@media screen and (max-width: 1024px) {
  .timeWrapper {
    width: 3.25rem;
  }
  .timeSlotsWrapper {
    width: 23.25rem;
  }
}

@media screen and (max-width: 560px) {
  .timeSlotsWrapper {
    gap: 10px;
  }

  .timeWrapper {
    /* padding: 10px 16px; */
  }
}

@media screen and (max-width: 767px) {
  .step2 {
    height: 30%;
  }
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .timeSlotsWrapper {
    margin-inline: 10px;
    width: 22.5rem;
    gap: 20px;
  }
}

@media screen and (max-width: 1023px) {
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 475px) {
  .timeSlotsWrapper {
    width: 15.5rem;
    gap: 10px;
  }
}

:root {
  --primary-color: #202328;
  --secondary-color: #4b5262;
  --border-color: #4b526233;
  --button-color: #ffbe00;
  --blue-color: #2aa9ff;
}
.wrapper {
  background-color: #fefbf6;
  height: 100vh;
}
#FEFBF6 * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Satoshi", sans-serif;
}
p {
  margin: 0;
}
button {
  background-color: var(--button-color);
  border: none;
  color: var(--primary-color);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 62px;
  cursor: pointer;
}
/* ---header--- */
header {
  height: 74px;
  padding: 16px 83px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 8px 0px #2023281a;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 99999;
}

.profile {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.dropdown-toggle::after {
  /* content: url("./images/ArrowDown.svg"); */
  border: none;
  margin-left: 0;
  height: 20px;
}

/* ----section-1----- */
.main_wrapper {
  background-color: #e1f3ff;
  display: flex;
  justify-content: end;
  /* padding-top: 74px; */
  position: relative;
}
.main_bg {
  position: absolute;
  left: 0;
  bottom: 0;
}
.main_section {
  padding: 52px 83px;
  padding-right: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}
.main_heading {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 332px;
}
.main_heading h1 {
  font-weight: 700;
  font-size: 32px;
  color: var(--primary-color);
  margin: 0;
}
.main_heading p {
  font-size: 16px;
  color: var(--secondary-color);
}
.main_box {
  background-color: white;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  width: 486px;
}
.main_box h3 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 8px;
}
.main_box p {
  font-size: 14px;
  color: var(--secondary-color);
  margin-bottom: 20px;
}
.main_box button {
  padding: 14px 0;
  width: 100%;
  font-size: 18px;
}

.table {
  width: 100%;
}

/* ----table-section---- */
.table_section {
  padding: 3% 5%;
}
.tbl {
  border: 1px solid var(--border-color) !important;
  border-radius: 12px;
}
table {
  margin: 0 !important;
}
table,
thead tr {
  border: none;
  border-radius: 12px;
}
th {
  border: none;
  border-radius: 12px;
  padding: 16px !important;
  font-weight: 500;
  color: var(--primary-color) !important;
  font-size: 16px;
}
.table_group_divider {
  border-top: 1px solid var(--border-color) !important;
}
tbody tr,
td {
  border-bottom: none !important;
  padding: 16px !important;
  border-radius: 12px;
  font-size: 14px;
  color: var(--secondary-color) !important;
}
td a {
  color: var(--blue-color) !important;
  font-weight: 500;
}
.btnCol button {
  padding: 12px 20px;
  height: 32px;
}

.appointmentTable {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color) !important;
  border-radius: 12px;
  margin-top: 25px;
}

.headingRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--border-color) !important;
  background-color: #e1f3ff;
}

.scheduleWith,
.scheduleWithHead {
  width: 28%;
  padding: 16px;
  font-weight: 900;
  font-family: Satoshi;
}

.scheduleWithHead,
.scheduleOnHead,
.meetingLinkHead,
.scheduleOnHeadResponsive,
.joinBtn {
  font-weight: 300 !important;
  font-family: Satoshi;
}

.scheduleOn,
.scheduleOnHead,
.scheduleOnHeadResponsive {
  width: 28%;
  padding: 16px;
  font-weight: 900;
  text-align: start;
  font-family: Satoshi;
}

.meetingLink,
.meetingLinkHead {
  width: 28%;
  padding: 16px;
  font-weight: 900;
  font-family: Satoshi;
}

.joinBtn {
  width: 16%;
  padding: 16px;
  font-family: Satoshi;
}

.tableDataWrapper {
  display: flex;
  flex-direction: column;
  max-height: 22vh;
  overflow: scroll;
}

.tableDataWrapper::-webkit-scrollbar {
  display: none;
}

.tableCol {
  width: 25%;
}

/* ----nodata--- */
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-block: 25px;
}
.noData_msg {
  width: 417px;
}
.noData h2 {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 12px;
}

.noData p {
  font-size: 14px;
  color: var(--secondary-color);
}

/* ----footer---- */
footer {
  padding: 1% 5%;
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: 700;
}
footer span {
  color: var(--secondary-color);
  font-weight: 700;
}

.btnCol {
  padding-block: 8px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #202328;
  font-family: Satoshi;
}

/* @media only screen and (min-width: 1440px) {
  .main_wrapper {
    background-position: center;
  }
} */

.scheduleOnHeadResponsive {
  display: none;
}

.responsiveDate {
  display: none;
}

.bookedSessions {
  font-size: 16px;
  font-weight: 400;
  color: #202328;
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  /* ---header--- */
  header {
    padding: 16px 56px;
  }
  /* ----section-1----- */
  .main_section {
    padding: 40px 56px;
    width: 76%;
    gap: 10px;
  }
  .main_heading {
    /* width: 300px; */
  }
  .main_heading h1 {
    font-size: 30px;
  }
  .main_bg {
    width: 500px;
  }
  .main_box {
    padding: 28px;
    width: 380px;
  }
  .main_box button {
    padding: 12px 0;
  }
  /* ----table-section---- */
  .table_section {
    padding: 56px 56px;
  }
  th {
    white-space: nowrap;
  }
  /* ----footer---- */
  footer {
    padding: 8px 56px 68px 56px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* ---header--- */
  header {
    height: 64px;
    padding: 16px 40px;
  }
  .logo {
    width: 190px;
  }
  /* ----section-1----- */
  .main_wrapper {
    /* height: 100%; */
    flex-direction: column;
    /* padding-top: 56px; */
  }
  .main_bg {
    position: relative;
    max-width: 80%;
  }
  .main_section {
    align-items: flex-start;
    padding: 36px 40px 40px 40px;
    flex-direction: column;
    width: 100%;
  }
  .main_heading {
    width: auto;
    margin-bottom: 16px;
  }
  .main_heading h1 {
    font-size: 26px;
    margin: 0;
  }
  .main_heading p {
    font-size: 14px;
  }
  .main_box {
    padding: 18px;
    width: 100%;
  }
  .main_box h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .main_box p {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .main_box button {
    padding: 10px 0;
    font-size: 16px;
    width: 25vw;
  }

  /* ----table-section---- */
  .table_section {
    padding: 40px;
  }
  th {
    white-space: nowrap;
  }
  .table-group-divider {
    border-top: 1px solid var(--border-color) !important;
  }
  /* ----footer---- */
  footer {
    padding: 8px 40px 52px 40px;
  }

  .meetingLink,
  .meetingLinkHead {
    display: none;
  }
  .scheduleWith,
  .scheduleOn,
  .scheduleOnHead,
  .scheduleWithHead,
  .scheduleOnHeadResponsive {
    width: 40%;
  }
  .joinBtn {
    width: 20%;
  }
  .wrapper {
    height: auto;
  }
}
@media only screen and (min-width: 180px) and (max-width: 767px) {
  .meetingLink,
  .meetingLinkHead,
  .scheduleOnHead {
    display: none;
  }
  /* ---header--- */
  header {
    height: 56px;
    padding: 8px 16px;
  }
  .logo {
    width: 150px;
  }
  .Profile_img {
    width: 36px;
  }
  /* ----section-1----- */
  .main_wrapper {
    /* height: 100%; */
    flex-direction: column;
    padding-top: 20px;
  }
  .main_bg {
    width: 100%;
    position: relative;
  }
  .main_section {
    align-items: flex-start;
    padding: 24px 16px 40px 16px;
    flex-direction: column;
    width: 100%;
  }
  .main_heading {
    width: auto;
    margin-bottom: 16px;
  }
  .main_heading h1 {
    font-size: 24px;
  }
  .main_heading p {
    font-size: 14px;
  }
  .main_box {
    padding: 16px;
    border-radius: 8px;
    width: auto;
  }
  .main_box h3 {
    font-size: 24px;
    margin-bottom: 6px;
  }
  .main_box p {
    font-size: 14px;
    margin-bottom: 14px;
  }
  .main_box button {
    padding: 8px 0;
    font-size: 16px;
    width: 35vw;
  }

  /* ----table-section---- */
  .table_section {
    padding: 40px 16px;
  }
  .tbl {
    border-radius: 8px;
  }
  table,
  thead tr {
    border-radius: 8px;
  }
  th {
    border-radius: 8px;
    padding: 12px !important;
    font-size: 14px;
    vertical-align: middle;
    white-space: nowrap;
  }
  tbody tr,
  td {
    padding: 8px !important;
    border-radius: 12px;
    font-size: 12px;
  }
  td button {
    padding: 4px 20px;
    height: 30px;
  }
  .tbl {
    border-right: none !important;
  }
  .table {
    border-right: 1px solid var(--border-color) !important;
  }
  /* ----footer---- */
  footer {
    padding: 0 16px 48px 16px;
    font-size: 14px;
    max-width: 80%;
  }
  .scheduleWith,
  .scheduleOn,
  .scheduleOnHead,
  .scheduleWithHead,
  .scheduleOnHeadResponsive {
    width: 35%;
  }

  .scheduleOnHeadResponsive {
    width: 38%;
  }
  .joinBtn {
    width: 30%;
  }

  .scheduleOnHeadResponsive {
    display: block;
  }
  .noData {
    padding-inline: 16px;
  }
  .responsiveDate {
    display: block;
  }
  .wrapper {
    height: auto;
  }
}

@media only screen and (max-width: 560px) {
  .main_box button {
    width: 100%;
  }
  .scheduleOn,
  .scheduleWith {
    font-size: 14px;
  }
  .scheduleOnHead,
  .scheduleWithHead,
  .scheduleOnHeadResponsive,
  .joinBtn {
    font-size: 14px;
  }

  .scheduleOnHeadResponsive {
    width: 35%;
  }
}

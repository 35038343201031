.cardsWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2%;
  margin: 15px 14.5%;
}

.serviceCard {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 32%;
  padding: 15px;
  background-color: var(--card-color-opacity-50);
  border-radius: 25px;
  font-family: "HelveticaNeue";
  font-weight: 400;
  margin-bottom: 2%;
}

.cardBtn p {
  margin: 0px;
  line-height: 8px;
}

.description {
  font-family: HelveticaNeue;
}

.serviceTitle {
  font-size: 20px;
  line-height: 20px;
  color: var(--service-name-header);
  font-family: "HelveticaNeue";
  font-weight: 400;
  margin-bottom: 4px;
}
.serviceDescription {
  font-size: 10px;
  line-height: 14px;
  font-family: HelveticaNeue;
  font-weight: 300;
  margin-bottom: 10px;
}
.illustration {
  height: 50px;
  width: 60px;
}
.serviceCardBtnRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
}

.btnArrow {
  width: 8px;
  height: 8px;
  margin-left: 4px;
}

.serviceBookBtn {
  padding: 7px 12px;
  color: var(--button-text-color);
  border-radius: 33px;
  font-family: PublicSans;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--card-button-background);
  border: 1px solid #f3fffe;
  cursor: pointer;
}

.doctorsWrapper {
  display: flex;
  flex-direction: row;
  padding: 6px 10px;
  background-color: var(--service-cards-background);
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  margin-top: 15px;
}

@media screen and (max-width: 1200px) {
  .cardsWrapper {
    margin-inline: 5%;
  }
}

@media screen and (max-width: 1024px) {
  .cardsWrapper {
    margin-inline: 1%;
  }
}

@media screen and (max-width: 1023px) {
  .cardBtn {
    height: 35px;
    width: 70px;
    font-size: 12px;
  }
  .doctorsWrapper {
    padding: 10px;
    margin-top: 15px;
    height: 68px;
    justify-content: flex-start;
  }
  .docImage {
    margin-right: 10px;
  }

  .illustration {
    /* height: 50px;
    width: 50px; */
  }
  .p1 {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .p2 {
    font-size: 10px;
    line-height: 10px;
  }
  .describe {
    height: 50px;
  }
  .cardSubDiv1 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .serviceCard {
    width: 49%;
  }

  .cardBtn {
    height: 40px;
    width: 90px;
    font-size: 14px;
    position: relative;
    top: 15px;
  }
  .btnArrow {
    height: 12px;
    width: 12px;
  }
  .doctorsWrapper {
    padding: 10px;
    margin-top: 5px;
    height: 68px;
  }

  .illustration {
    /* height: 40px !important;
    width: 40px !important; */
  }
  .p1 {
    font-size: 20px;
    line-height: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .p2 {
    display: none;
  }
  .describe {
    height: 40px;
  }

  .mainDiv {
    padding-top: 20px;
  }
}

@media screen and (max-width: 560px) {
  .serviceCard {
    width: 100%;
  }
  .cardsWrapper {
    margin-inline: 5%;
    margin-bottom: 15vh !important;
  }
}

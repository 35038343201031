.mainContainer {
  padding-inline: 40px;
  background-color: #fefbf6;
  height: inherit;
}
.headingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.moodImg {
  height: 130px;
  width: 150px;
}

.headingContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  gap: 4px;
}

.headingHr {
  border: 1px solid #ff875c;
  margin-bottom: 12px;
}

.heading {
  font-family: var(--satoshi-font);
  color: #1e1e1e;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subHeading {
  font-family: var(--satoshi-font);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.detailRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  margin-block: 48px;
}

.detailColumn,
.detailColumnPhone,
.detailColumnPhoneDisabled {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.detailLabel {
  color: #4b4657;
  font-family: var(--satoshi-font);
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.2px;
  font-weight: 500;
}

.profileDisabledInput {
}

.profileInput {
  border-color: #e6e5ea;
  height: 48px;
  font-family: var(--satoshi-font);
  font-size: 16px;
}

.profileInputDisabled {
  border-color: #e6e5ea;
  height: 48px;
  background-color: #faf9f7;
  font-family: var(--satoshi-font);
  font-size: 16px;
}

.detailColumnPhone input {
  border-color: #e6e5ea !important;
  height: 48px !important;
  width: 80% !important;
  border-radius: 11px !important;
  font-family: var(--satoshi-font);
  font-size: 16px;
}

.detailColumnPhoneDisabled input {
  border-color: #e6e5ea !important;
  height: 48px !important;
  width: 80% !important;
  border-radius: 11px !important;
  background-color: #faf9f7 !important;
  font-family: var(--satoshi-font);
  font-size: 16px;
}

.genderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}

.activeGender {
  padding: 10px 60px;
  border-radius: 11px;
  background-color: transparent;
  opacity: 1;
  border: 1px solid black;
  font-family: var(--satoshi-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.2px;
}

.selectedDisabledGender {
  background-color: #faf9f7;
  padding: 10px 60px;
  border-radius: 11px;
  border: 1px solid #e6e5ea;
  color: #c3c0cc;
  font-family: var(--satoshi-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.2px;
}

.gender {
  padding: 10px 60px;
  border: 1px solid #e6e5ea;
  opacity: 0.3;
  border-radius: 11px;
  background-color: transparent;
  font-family: var(--satoshi-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.2px;
}

.profileButton {
  margin-top: 20px;
  padding-block: 22px;
  width: 35%;
  background-color: #ecbd00;
  color: #000000;
  text-align: center;
  font-family: var(--satoshi-font);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 19px;
  letter-spacing: 0.2px;
  border: none;
  border-radius: 11px;
  cursor: pointer;
}

@media screen and (min-width: 1200px) {
  .profileButton {
    width: 25%;
  }
  .mainContainer {
    padding-inline: 10%;
  }
}

@media screen and (min-width: 1024px) {
}

@media screen and (min-width: 1650px) {
  .profileButton {
    width: 20%;
  }
}

@media screen and (max-width: 1024px) {
  .detailRow {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .profileButton {
    width: 40%;
  }
}

@media screen and (max-width: 560px) {
  .detailRow {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-block: 0px;
    margin-top: 20px;
  }
  .detailColumn,
  .detailColumnPhone,
  .detailColumnPhoneDisabled {
    width: 100%;
  }
  .gender {
    padding: 10px 30px;
  }
  .activeGender {
    padding: 10px 30px;
  }
  .selectedDisabledGender {
    padding: 10px 30px;
  }
  .profileButton {
    width: 50%;
  }
  .mainContainer {
    padding-bottom: 20vh;
  }
}
@media screen and (max-width: 425px) {
  .mainContainer {
    padding-inline: 25px;
  }
}

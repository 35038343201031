:root {
    --card-color: #f0f0f0;
    --card-color-opacity-50: rgba(240, 240, 240, 0.5);
    --service-name-header: #147c65;
    --button-text-color: #1f6360;
    --button-text-color-secondary: #2545eb;
    --card-button-background: #e5eeeb;
    --selection-color: rgba(160, 235, 218, 0.4);
    --service-cards-background: #ffffff;
    --appointment-details-selection-heading: #147c65;
    --appointment-steps-background: #f8f8f8;
    --upcoming-appointment-details-background: #ffffff50;
    --upcoming-appointment-camera-background: #fff1e7;
    --availability-modal-text-color: #5c5c5c;
    --modal-overlay: #00000050;
    --text-color: #000000;
    --auth-form-heading-text-color: #464646;
    --auth-cts-heading: #9ca3ab;
    --error-text-color: #d50000;
    --further-instructions-text-color: #00000080;
    --booking-confirmation-service-name-text-color: #147c65;
    --dropin-modal-border-color: #4ba998;
    --droping-header-background-color: #f4f4f4;
    --dropin-body-header-text-color: #113a39;
    --dropin-app-screen-text-color: #b3c0bd;
    --dropin-download-link-text-color: #546362;
    --footer-container-background-color: #e0f3f3;
    --login-button0warning-text-color: #ff705c;
    --auth-left-panel-text-color: #64688b;
    --auth-subtitle-text-color: #515c67;
    --dashboard-header-gradient: radial-gradient(258.58% 700.05% at 161.04% 288.68%, #A0EBDA 0%, #E7F2F0 71.49%);
  }

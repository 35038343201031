.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  padding-block: 40px;
  justify-content: center;
  background-color: #fefbf6;
  height: calc(100vh - 50px);
}
.mainContainer::webkit-scrollbar {
  display: none;
}

.contentWrapper {
  /* width: 32%; */
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 0px;
}

.infoText {
  display: flex;
  flex-direction: column;
  width: 35%;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Cabin-regular;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--further-instructions-text-color);
  margin-top: 10px;
  font-family: var(--satoshi-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: center;
}

.infoText p {
  /* font-family: Gelica-light; */
}

.doctorDetailsWrapper {
  background-color: #e1f3ff;
  border-radius: 11px;
  mix-blend-mode: multiply;

  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.docImg {
  height: 80px;
  border-radius: 10px;
}

p {
  margin: 0px;
}

.doctorDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  gap: 8px;
}

.dateContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.date {
  padding-block: 15px;
  width: 48%;
  background-color: #e1f3ff;
  mix-blend-mode: multiply;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cabin-regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  font-family: var(--satoshi-font);
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
}

.note {
  background-color: var(--summary-box-color);
  mix-blend-mode: multiply;
  border-radius: 11px;
  height: 134px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-family: Cabin-regular;
  font-weight: 400;
  font-size: 10px;
}

.notesTextbox {
  background: var(--service-cards-background);
  mix-blend-mode: normal;
  opacity: 0.4;
  border-radius: 10px;
  height: 100%;
  margin-top: 5px;
  padding: 5px;
}

.input {
  margin-top: 10px !important;
  height: 80px !important;
}
.headingText {
  /* font-family: Gelica; */
  font-family: var(--satoshi-font);
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #202328;
  text-align: center;
}

.doctorNameWrapper {
  display: flex;
  flex-direction: column;
}

.meetingWithText {
  /* font-family: Cabin-regular; */
  font-family: var(--satoshi-font);
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #202328;
}
.time {
  /* font-family: Cabin-regular; */
  font-family: var(--satoshi-font);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4b5262;
}

.serviceName {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  margin-top: 20px;
}

.serviceName {
  font-size: 42px;
  line-height: 35px;
  font-family: Gelica-medium;
  font-weight: 400;
  text-align: center;
}
.finishBtn {
  padding-inline: 25px;
  padding-block: 12px;
  color: var(--button-text-color-secondary);
  text-align: center;
  border-radius: 33px;
  border: none;

  background-color: var(--selection-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  font-size: 16px;
  font-family: Cabin-bold;
  font-weight: bold;
  cursor: pointer;
  font-family: var(--satoshi-font);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
}

.scheduleOn {
  font-family: var(--satoshi-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
}

@media screen and (min-width: 1440px) {
  .contentWrapper {
    width: 40%;
  }

  .infoText {
    width: 35%;
  }
}

@media screen and (max-width: 1200px) {
  .contentWrapper {
    width: 45%;
  }
}

@media screen and (max-width: 1024px) {
  .contentWrapper {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    width: 65%;
  }

  .infoText {
    width: 60%;
  }

  .headingText {
    width: 90%;
  }
  .mainContainer {
    min-height: unset;
  }
}

@media screen and (max-width: 560px) {
  .contentWrapper {
    width: 90%;
  }
  .fig1 {
    display: none;
  }
  .line1 {
    display: none;
  }
  .line2 {
    display: none;
  }
  .fig2 {
    position: static;
    margin-bottom: 30px;
  }
  .infoText {
    width: 80%;
  }
  .note {
    height: 140px;
  }
  .mainContainer {
    padding-bottom: 20vh;
  }
}

@media screen and (max-width: 475px) {
  .dateContainer {
    flex-direction: column;
    gap: 10px;
  }
  .date {
    width: 100%;
  }
}

.mainContainer{
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    left: 35%;
    right: 35%;
    background-color: transparent;
}

.subContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(83.38% 93.33% at 5.84% -13.35%, #CCEFE8 0%, #FFF1E7 100%);
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    height: 423px;
    width: 475px;
    padding: 50px;
}

.steps{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: HelveticaNeue;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 30px;
}

.head{
    font-family: PublicSans;
font-style: normal;
font-weight: bold;
font-size: 25px;
line-height: 25px;
color: var(--service-name-header);
}

.cardBtn{
    width: 220px;
    height: 55px;
    color: var(--button-text-color-secondary);
    text-align: center;
    border-radius: 33px;
    border: 1px solid var(--button-text-color-secondary);
    font-family: PublicSans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    cursor: pointer;
    outline: none;
}

.dwnArrow{
    margin-top: 15px;
    margin-bottom: 15px;
}

.cardBtn p{
    margin: 0px;
}

.btnArrow{
    width: 8px;
    height: 8px;
    margin-left: 4px;
}

@media screen and  (max-width:1024px) {
    .mainContainer{
        left: 30%;
        right: 30%;
    }
}
@media screen and  (max-width:767px) {
    .subContainer{
        width: 100%;
    }
    .steps{
        width: 75%;
    }
    .mainContainer{
        left: 15%;
        right: 15%;
    }
}
@media screen and  (max-width:480px) {
    .mainContainer{
        left: 5%;
        right: 5%;
    }
}
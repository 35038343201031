.mainContainer {
  padding-left: 5rem;
  background-color: #fefbf6;
  padding-right: 8rem;
  padding-top: 20px;
}

button:focus {
  outline: none !important;
}

.contentWrapper {
  height: 100%;
  padding-top: 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: transparent;
  /* padding-bottom: 5vh; */
  /* max-height: 65vh; */
}

.bookingStepsWrapper {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
  position: relative;
}
.coverDiv {
  height: 100%;
  background-color: #fefbf6;
  overflow: scroll;
}

.coverDiv::-webkit-scrollbar {
  display: none;
}

.proceedButton {
  width: 103px;
  height: 66px;
  border: 1px solid var(--button-text-color-secondary);
  border-radius: 33px;
  color: var(--button-text-color-secondary);
  background-color: transparent;
  outline: none;
}

.nextBtnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.buttonDiv {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "PublicSans";
  font-weight: 700;
  font-size: 14px;
  gap: 24px;
  /* position: absolute;
  bottom: 0px;
  right: 0px; */
}
.nextBtn {
  padding: 12px 32px;
  /* color: var(--button-text-color-secondary); */
  color: #202328;
  font-family: var(--satoshi-font);
  font-weight: 700;
  border-radius: 62px;
  /* border: 1px solid var(--button-text-color-secondary); */
  background-color: var(--booking-flow-button-background);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bookBtn {
  width: 215px;
  height: 55px;
  color: var(--button-text-color-secondary);
  text-align: center;
  border-radius: 33px;
  border: 1px solid var(--button-text-color-secondary);
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
}
.backText {
  margin: 0px;
  /* font-family: "PublicSans"; */
  font-weight: 700;
  font-size: 16px;
  /* margin-left: 5px; */
}
.next {
  margin: 0px;
  font-family: var(--satoshi-font);
  font-weight: 700;
  font-size: 16px;
  /* margin-right: 7px; */
}

.almost {
  font-family: HelveticaNeue;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: var(--button-text-color-secondary);
}
.backButtonWrapper {
  color: var(--button-text-color-secondary);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.bookingStepsNavbar {
  display: flex;
  flex-direction: row;
  padding: 40px;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
}

.modalStyles {
  background-color: yellowgreen;
  background: var(--service-cards-background);
  mix-blend-mode: normal;
  border-radius: 25px;
  position: absolute;
  width: fit-content;
  z-index: 20;
  height: fit-content;
  inset: 40px;
  border-radius: 25px;
  border: none;
  margin: auto;
}

.modalOverlay {
  position: fixed;
  inset: 0px;
  background-color: var(--modal-overlay);
}

.p1 {
  color: var(--button-text-color);
  font-family: PublicSans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
}

.p2 {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--availability-modal-text-color);
  margin-bottom: 32px;
}

.modalButton {
  width: 180.25px;
  height: 44px;
  color: var(--button-text-color);
  border-radius: 33px;
  border: none;
  color: var(--service-cards-background);
}

.modalCloseDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding-right: 3rem;
  padding-top: 2rem;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  padding-bottom: 4rem;
  margin-inline: 10vw;
}

.paymentfailed {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #004b75;
}

.dashboardLink {
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.loadingDiv {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paymentContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1441px) {
  .almost {
    left: 7%;
  }
}

@media screen and (max-width: 1200px) {
  .mainContainer {
    padding-right: 5%;
    padding-left: 5%;
  }
  .contentWrapper {
    height: 100%;
  }
  .almost {
    left: 5%;
  }
  .bookingStepsWrapper {
    width: 60%;
  }
}

@media screen and (max-width: 1023px) {
  .mainContainer {
    padding-right: 1%;
    padding-left: 1%;
  }
  .almost {
    left: 0%;
  }
  .modalContainer {
    padding-bottom: 3rem;
  }
  .modalCloseDiv {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .nav {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 767px) {
  .contentWrapper {
    flex-direction: column;
  }
  .mainContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .bookingStepsWrapper {
    width: 100%;
    height: inherit;
    margin-top: 40px;
    padding-bottom: 15vh;
  }
  .modalContainer {
    padding-bottom: 3rem;
  }
  .loadingDiv {
    width: 100%;
    margin-top: 10%;
  }
  .buttonDiv {
    position: static;
    padding-bottom: 5vh;
  }
}

@media screen and (max-width: 560px) {
  .mainContainer {
    padding-inline: 5%;
  }
  .almost {
    left: 2%;
  }
  .modalContainer {
    margin-inline: 0vw;
    padding-bottom: 3rem;
  }
  .modalCloseDiv {
    padding-right: 1rem;
  }
  .nextBtn {
    /* padding: 12px 16px; */
  }
  .contentWrapper {
    /* padding-bottom: 20vh; */
  }
}
